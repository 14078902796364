import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { HiOutlinePlusCircle } from "react-icons/hi2";

import BuyerBrandsRowsDisplay from "@app/modules/brand/BuyerBrandsRowsDisplay";
import NewBrandForm from "@app/modules/brand/NewBrandForm";
import Collapse from "@components/data-display/Collapse";
import { PageHeader, PageTitle } from "@components/data-display/Page";
import Button from "@components/data-entry/Button";
import Loading from "@components/feedback/Loading";
import Modal, { useModal } from "@components/feedback/Modal";
import { GetBuyerBrands } from "@services/api/brands/GetBuyerBrands";
import LogService from "@services/log/service";
import { withBuyerOnly } from "@shared/components/guards/authentication";
import ConditionalRender from "@shared/components/on";

interface CollapseStates {
  [key: string]: boolean;
}

function BuyerCrmPage() {
  const {
    data: buyerBrands,
    status: buyerBrandsStatus,
    error: buyerBrandsError,
  } = GetBuyerBrands.useHook();

  const { t } = useTranslation();

  // modal state
  const modalState = useModal();

  // track collapse states
  const [collapseStates, setCollapseStates] = useState<CollapseStates>(() => {
    if (buyerBrands) {
      return buyerBrands.reduce((acc, _, index) => {
        const key = `isCollapsed-${index}`;
        return { ...acc, [key]: false };
      }, {});
    }
    return {};
  });

  const [selectedSeason, setSelectedSeason] = useState<string | null>(null);

  // Create a list of unique seasons from all showrooms
  const seasons = useMemo(() => {
    const uniqueSeasons = new Set(
      buyerBrands?.flatMap((brand) =>
        (brand.organization?.showrooms || []).map(
          (showroom) => `${showroom.season} ${showroom.year}`,
        ),
      ),
    );
    return Array.from(uniqueSeasons).sort();
  }, [buyerBrands]);

  if (buyerBrandsStatus === "pending") {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loading type="screen" />
      </div>
    );
  }

  if (buyerBrandsError) {
    LogService.error(buyerBrandsError.stack);
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <p className="text-3xl mb-4 font-bold text-primaryElectricBlue">
            {t("BuyerAccount.crm.error.title")}
          </p>
          <p className="text-lg">{t("BuyerAccount.crm.error.sub-title")}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      {buyerBrands ? (
        <>
          <PageHeader>
            <div className="flex flex-row">
              <PageTitle>{t("BuyerAccount.crm.title")}</PageTitle>
            </div>
            <div>
              <Button
                type="button"
                theme="PRIMARY"
                className="self-end w-full"
                onClick={() => modalState.open()}
              >
                {t("BuyerAccount.crm.create-brand.add-new-brand")}
                <HiOutlinePlusCircle className="w-6 h-6" />
              </Button>
              <Modal
                title={t("BuyerAccount.crm.create-brand.add-new-brand")}
                state={modalState}
                centeredTitle
                padding="p-9"
                aria-label={t("Shared.order-deadline.add-order-deadline")}
              >
                <div className="px-28">
                  <NewBrandForm onSuccess={async () => modalState.close()} />
                </div>
              </Modal>
            </div>
          </PageHeader>
          <ConditionalRender renderIf={seasons.length > 1}>
            <div className="flex flex-row border border-transparent rounded-md pl-8 pt-10">
              <p className="flex items-center text-sm p-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-l-md px-2">
                {t("BuyerAccount.crm.filter-season")}
              </p>
              <select
                id="season-dropdown"
                aria-label="season-dropdown"
                value={selectedSeason || ""}
                onChange={(e) => setSelectedSeason(e.target.value || null)}
                className="form-select block border rounded-r-md shadow-sm border-primaryElectricBlue focus:border-primaryElectricBlue bg-primaryLightElectricBlue sm:text-sm text-primaryElectricBlue caret-primaryElectricBlue"
              >
                <option value="">All Seasons</option>
                {seasons.map((season) => (
                  <option key={season} value={season}>
                    {season}
                  </option>
                ))}
              </select>
            </div>
          </ConditionalRender>
          <div className="w-full">
            <div className="pl-8 mt-7 mb-2 grid grid-cols-1">
              <p className="pl-2 font-light text-left px-3 text-sm col-span-1">
                {t("BuyerAccount.crm.main-table-header.brand-name")}
              </p>
            </div>
            <hr />
            <div className="px-8 pt-1 mt-5">
              {buyerBrands.map((buyerBrand, index) => {
                const filteredShowrooms = selectedSeason
                  ? buyerBrand.organization?.showrooms.filter(
                      (showroom) =>
                        `${showroom.season} ${showroom.year}` ===
                        selectedSeason,
                    )
                  : buyerBrand.organization?.showrooms || [];

                return (
                  <Collapse
                    uncollapsable={filteredShowrooms?.length === 0}
                    message="No showrooms available"
                    toggleText="Showrooms"
                    key={index}
                    title={
                      <div className="w-full grid grid-cols-2 items-cente">
                        <div className="pl-4 text-left font-medium col-span-1 m-1">
                          {buyerBrand.name}
                        </div>
                      </div>
                    }
                    className={`gap-2mt-2mb-0 px-4 w-full ${
                      collapseStates[`isCollapsed-${index}`]
                        ? "border border-dashed rounded-lg block border-primaryElectricBlue p-8"
                        : "border rounded-lg border-gray-300 p-8 "
                    }`}
                    buttonClassName="!px-0 !pt-0 "
                    contentClassName="!px-0 !pb-0 mb-4 "
                    collapsed={collapseStates[`isCollapsed-${index}`]}
                    onToggle={() => {
                      setCollapseStates((prevStates) => ({
                        ...prevStates,
                        [`isCollapsed-${index}`]:
                          !prevStates[`isCollapsed-${index}`],
                      }));
                    }}
                    titleJustify="between"
                    addText
                  >
                    <BuyerBrandsRowsDisplay
                      key={buyerBrand.id}
                      buyerBrand={{
                        ...buyerBrand,
                        showrooms: filteredShowrooms || [],
                      }}
                    />
                  </Collapse>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <div className="text-center">
            <p className="text-3xl mb-4 font-bold text-primaryElectricBlue">
              {t("BuyerAccount.crm.empty.title")}
            </p>
            <p className="text-lg">{t("BuyerAccount.crm.empty.sub-title")}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default withBuyerOnly(BuyerCrmPage);
