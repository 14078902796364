import React, { useMemo, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { z } from "zod";

import InputLabel from "@components/data-display/InputLabel";
import Button from "@components/data-entry/Button";
import TextField from "@components/data-entry/TextField";
import { AddBuyerBrand } from "@services/api/brands/AddBuyerBrand";
import { InviteBuyerBrand } from "@services/api/brands/InviteBuyerBrand";
import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";

const formValuesSchema = z.object({
  brandId: z.string().uuid().optional(),
  brandName: z.string().optional(),
  email: z.string().email().optional(),
  firstname: z.string().optional(),
  lastname: z.string().optional(),
});
type FormValues = z.infer<typeof formValuesSchema>;

const validNewBrandFormValuesSchema = z.object({
  brandId: z.literal(""),
  brandName: z.string(),
  email: z.string().email({ message: "Common.form.this-is-not-valid-email" }),
  firstname: z.string(),
  lastname: z.string(),
});
const validExistingBrandFormValuesSchema = z.object({
  brandId: z.string().uuid(),
});
function NewBrandForm({ onSuccess }: { onSuccess?: () => Promise<void> }) {
  const { t } = useTranslation();
  const [sendEmailInvitation, setSendEmailInvitation] = useState(false);
  const { data: brands } = GetAllBrandsEndpoint.useHook();

  const { mutate: inviteBuyerBrand } = InviteBuyerBrand.useHook();
  const { mutate: addBuyerBrand } = AddBuyerBrand.useHook();

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      brandId: "",
      brandName: "",
      email: "",
      firstname: "",
      lastname: "",
    },
    resolver: zodResolver(
      sendEmailInvitation
        ? validNewBrandFormValuesSchema
        : validExistingBrandFormValuesSchema,
    ),
  });

  const values = watch();

  // check if brand name and brand id are empty
  // to avoid displaying the "brand name missing" error after the brand is created
  const noBrandNameError = useMemo(
    () => !values.brandName && !values.brandId,
    [values.brandName, values.brandId],
  );

  // handle form submission
  const onSubmitForm = async (data: FormValues) => {
    const parseNewBrandValues = validNewBrandFormValuesSchema.safeParse(data);
    const parseExistingBrandValues =
      validExistingBrandFormValuesSchema.safeParse(data);
    if (parseNewBrandValues.success) {
      await inviteBuyerBrand({
        data: {
          mainContactEmail: parseNewBrandValues.data.email,
          mainContactFirstname: parseNewBrandValues.data.firstname,
          mainContactLastname: parseNewBrandValues.data.lastname,
          name: parseNewBrandValues.data.brandName,
        },
      });
      onSuccess?.();
    } else if (parseExistingBrandValues.success) {
      await addBuyerBrand({
        data: {
          brandId: parseExistingBrandValues.data.brandId,
        },
      });
      onSuccess?.();
    }
  };

  // render brand name field
  const renderBrandNameField = () => (
    <div className="mt-5 relative">
      <InputLabel error={formErrors.brandId?.message}>
        {t("BuyerAccount.crm.create-brand.brand-label")}
      </InputLabel>
      <div>
        <Controller
          name="brandId"
          control={control}
          render={({ field: { onChange } }) => (
            <CreatableSelect
              isClearable
              name="brandId"
              className="mt-1"
              options={brands?.map((brand) => ({
                label: brand.name,
                value: brand.id,
              }))}
              placeholder={t("BuyerAccount.crm.create-brand.brand-placeholder")}
              value={
                values.brandName
                  ? { label: values.brandName, value: values.brandId }
                  : null
              }
              onCreateOption={(brandName) => {
                onChange("");
                setValue("brandName", brandName);
                setSendEmailInvitation(true);
              }}
              onChange={(o) => {
                onChange(o?.value || "");
                setValue("brandName", o ? o.label : "");
                setSendEmailInvitation(false);
              }}
              aria-label={t("BuyerAccount.crm.create-brand.brand-label")}
            />
          )}
        />
        {formErrors.brandId && noBrandNameError && (
          <span className="text-primaryRed text-sm">
            {formErrors.brandId.message}
          </span>
        )}
      </div>
    </div>
  );

  const renderContactFields = () => (
    <div className="mt-4 grid grid-cols-2 gap-x-4">
      <InputLabel error={formErrors.firstname?.message}>
        {t("BuyerAccount.crm.create-brand.firstname-label")}
      </InputLabel>
      <InputLabel error={formErrors.lastname?.message}>
        {t("BuyerAccount.crm.create-brand.lastname-label")}
      </InputLabel>
      <div>
        <Controller
          name="firstname"
          control={control}
          render={({ field: { onChange, value, onBlur } }) => (
            <TextField
              className="mt-1 rounded-md"
              id="firstname"
              placeholder={t(
                "BuyerAccount.crm.create-brand.firstname-placeholder",
              )}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>
      <div>
        <Controller
          name="lastname"
          control={control}
          render={({ field: { onChange, value, onBlur } }) => (
            <TextField
              className="mt-1 rounded-md"
              id="lastname"
              placeholder={t(
                "BuyerAccount.crm.create-brand.lastname-placeholder",
              )}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>
    </div>
  );

  // render email field
  const renderEmailField = () => (
    <div className="mt-4">
      <InputLabel
        error={
          formErrors.email?.message ? t(formErrors.email?.message) : undefined
        }
      >
        {t("BuyerAccount.crm.create-brand.email-label")}
      </InputLabel>
      <div>
        <Controller
          name="email"
          control={control}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <TextField
              className="mt-1 rounded-md"
              id="email"
              placeholder={t("BuyerAccount.crm.create-brand.email-placeholder")}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              aria-invalid={formErrors.email ? "true" : "false"}
              hasError={!!error}
              grayCloseIcon // to match the gray color of the close icon in the CreatableSelect
              aria-label={t("BuyerAccount.crm.create-brand.email-label")}
            />
          )}
        />
        {formErrors.email && formErrors.email.type === "required" && (
          <p className="text-sm italic text-primaryRed">
            {t("Common.form.this-is-required")}
          </p>
        )}
        {formErrors.email && formErrors.email.type === "invalid" && (
          <p className="text-sm italic text-primaryRed">
            {t("Common.form.this-is-not-valid-email")}
          </p>
        )}
      </div>
    </div>
  );

  const renderSubmitButton = () => (
    <Button
      className="whitespace-nowrap text-primaryElectricBlue hover:underline mt-4 flex w-full"
      theme="SECONDARY"
      justify="center"
      type="submit"
    >
      {sendEmailInvitation
        ? t("BuyerAccount.crm.create-brand.send-invitation")
        : t("BuyerAccount.crm.create-brand.add-brand")}
    </Button>
  );

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div>
        {!sendEmailInvitation && (
          <div>
            <p>{t("BuyerAccount.crm.create-brand.fill-form")}</p>
          </div>
        )}
        {sendEmailInvitation && (
          <div className="mt-2 mb-2 w-85">
            <Trans
              i18nKey={t("BuyerAccount.crm.create-brand.invite-brand-message")}
            />
          </div>
        )}
        {renderBrandNameField()}
        {sendEmailInvitation && (
          <>
            {renderContactFields()}
            {renderEmailField()}
            <div className="italic text-sm text-gray-400 mt-8">
              <Trans
                i18nKey={t("BuyerAccount.crm.create-brand.invite-brand-value")}
              />
            </div>
          </>
        )}
      </div>
      {renderSubmitButton()}
    </form>
  );
}

export default NewBrandForm;
