import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import axiosInstance from "@services/api/config";

import { handleParsingError } from "../helper";
import { GetBuyerBrands } from "./GetBuyerBrands";

export namespace AddBuyerBrand {
  export const inputSerializingSchema = z.object({
    brandId: z.string().uuid(),
  });
  export type Input = z.input<typeof inputSerializingSchema>;

  export const outputHydratingSchema = brandSchema.pick({
    id: true,
    name: true,
  });
  export type Output = z.output<typeof outputHydratingSchema>;

  interface MutationParams {
    data: Input;
  }

  type Params = MutationParams;

  export const path = () => `/buyer/brands`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(), inputSerializingSchema.parse(params.data))
      .then((res) =>
        handleParsingError(
          outputHydratingSchema,
          res.data,
          "Could not add brand",
        ),
      );

  export function useHook() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: GetBuyerBrands.getQueryKeys(),
        });
      },
    });
  }
}
